import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

const ResumePage = () => (
  <Layout>
    <Seo title="About Me" />
    <div className="d-flex flex-column w-100">
      <div className="container">
        <div className="col-12">
          <h1>A Little About Me</h1>
        </div>
        <div className="col-12">
          <p>
            I am currently living and working in the Okanagan of British Columbia Canada. 
            I have found my passion and am one of the lucky ones who gets to do what they 
            enjoy every day. I always had this craving for change and software development 
            has effectively tamed this craving. As the more you learn the more you realise 
            you don't know and there are just so many avenues that I am able to travel down now.
          </p>
          <p>
            The company I work for QHR Technologies mission is to unite Canadian healthcare, connecting Providers and their patients to information and each other.
            This is something to be proud of and resonates with me. I feel I am able to help others through what we provide and I have purpose.
          </p>
          <p>- reach out if you want to know more</p>
        </div>
        
      </div>
      <div className="flex-fill row page-bg bg-clip"></div>
    </div>
    
  </Layout>
)

export default ResumePage
